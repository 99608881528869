import React from 'react';
import {FormattedMessage} from 'react-intl';

function isNull(el) {
  return undefined === el || null === el;
}

export const CURRENT_ALEXPROMONITOR_VERSION = '1.0.0.0';
export const CURRENT_TESTCONNECT_VERSION = '2.0.0.0';
export const CURRENT_SMARTLABCLIENT_VERSION = '3.7.2.0';
export const CURRENT_SMARTLABSERVICE_VERSION = '3.7.2.0';
export const CURRENT_XFCSERVICE_VERSION = '1.1.8.3';
export const CURRENT_XFCDEVICE_VERSION = '0.15';

export const reportDateFormat = 'MMM D, YYYY';
export const brand = 'BioLab, Inc';
export const xfName = 'AccuFlex™';
export const SCAN_ERROR_KEY = 'SCAN_ERROR_KEY';
export const LSKEY_SCANNER = 'SELECTED_SCANNER';
export const manualScanner = 'Manual';

export const dateTimeFormats = {
  numeralDate: 'L',
  numeralDateTime: 'L LT',
  monthDateTime: 'LL',
};

export const truBlueStatus = {
  Active: 'ACTIVE',
  Inactive: 'INACTIVE',
  Eligible: 'ELIGIBLE',
  Ineligible: 'INELIGIBLE',
  Opportunity: 'OPPORTUNITY',
};

export const xfcScanStatuses = {
  RESULTS: 'Results',
  INSERT_SHUTTLE: 'InsertingShuttle',
  INSERT_SHUTTLE_NOT_IN: 'InsertingShuttle_NotInYet',
  ANALYZING: 'Analyzing',
};

export const xfcExactValue = 'Exact';
export const xfcUntestedValue = 'Untested';
export const xfcBromineSanitizerValue = 'Bromine';
export const xfcFreeChlorineId = 'FC';
export const xfcGreaterThan = '>';
export const xfcLessThan = '<';

export const truBlueStatusFormat = formatMessage => {
  if (isNull(formatMessage)) {
    return truBlueStatus;
  }

  return {
    Active: formatMessage({id: 'general.active'}).toUpperCase(),
    Inactive: formatMessage({id: 'general.inactive'}).toUpperCase(),
    Eligible: formatMessage({id: 'general.eligible'}).toUpperCase(),
    Ineligible: formatMessage({id: 'general.ineligible'}).toUpperCase(),
    Opportunity: formatMessage({id: 'general.opportunity'}).toUpperCase(),
  };
};

export const emailSendingTypes = {
  direct: 0,
  encyclopedia: 1,
  analysis: 2,
  seasonsUsage: 3,
  reportHistory: 4,
};

export const systemTypes = {
  pool: 1,
  spa: 2,
  swimspa: 3,
};

export const systemTypeNames = {
  [systemTypes.pool]: 'Pool',
  [systemTypes.spa]: 'Spa',
  [systemTypes.swimspa]: 'Swim Spa',
};

export const systemTypeNamesFormat = formatMessage => {
  if (!isNull(formatMessage)) {
    return {
      1: formatMessage({id: 'general.pool'}),
      2: formatMessage({id: 'general.spa'}),
      3: formatMessage({id: 'general.swim.spa'}),
    };
  }
  return systemTypeNames;
};

export const trublueClaimStatuses = {
  open: 0,
  submitted: 1,
  approved: 2,
  moreInfo: 3,
  dismissed: 4,
  denied: 5,
  all: '',
};

export const trublueClaimStatusNames = {
  [trublueClaimStatuses.open]: 'Open',
  [trublueClaimStatuses.submitted]: 'Submitted',
  [trublueClaimStatuses.approved]: 'Approved',
  [trublueClaimStatuses.moreInfo]: 'More Information',
  [trublueClaimStatuses.dismissed]: 'Dismissed',
  [trublueClaimStatuses.denied]: 'Denied',
  [trublueClaimStatuses.all]: 'All',
};

export const trublueClaimStatusIconId = {
  [trublueClaimStatuses.open]: 'bl-add',
  [trublueClaimStatuses.submitted]: 'bl-down_arrow',
  [trublueClaimStatuses.approved]: 'bl-checkmark_circle',
  [trublueClaimStatuses.dismissed]: 'bl-dismissed',
  [trublueClaimStatuses.denied]: 'bl-dismissed',
  [trublueClaimStatuses.moreInfo]: 'bl-attention',
};

export const trublueClaimStatusesFormat = formatMessage => {
  if (!isNull(formatMessage)) {
    return {
      0: formatMessage({id: 'trublue.claims.status.open'}),
      1: formatMessage({id: 'trublue.claims.status.submitted'}),
      2: formatMessage({id: 'trublue.claims.status.approved'}),
      3: formatMessage({id: 'trublue.claims.status.moreInfo'}),
      4: formatMessage({id: 'trublue.claims.status.dismissed'}),
      5: formatMessage({id: 'trublue.claims.status.denied'}),
      '': formatMessage({id: 'trublue.claims.status.all'}),
    };
  }
  return systemTypeNames;
};


export const systemTypeFullNamesFormat = formatMessage => {
  return {
    1: formatMessage({id: 'manifold.system.title.pool'}),
    2: formatMessage({id: 'manifold.system.title.spa'}),
    3: formatMessage({id: 'manifold.system.title.swimspa'}),
  };
};

export const productTypes = {
  sanitizer: 1,
  oxidizer: 2,
  maintOxidizer: 3,
  prevAlgicide: 4,
  pHAdjuster: 5,
  pHBuffer: 6,
  algaeProducts: 7,
  winterOxidizer: 8,
  winterAlgicide: 9,
  waterlineControl: 10,
  maintClarifier: 12,
  curativeClarifier: 13,
  smartPakProducts: 14,
  chlorineDemandProducts: 15,
  stabilizer: 16,
  scalePrevention: 17,
  taAdjuster: 18,
};

export const poolProductTypeNames = {
  1: 'Sanitizer',
  2: 'Oxidizer',
  3: 'Maintenance Oxidizer',
  4: 'Preventative Algicide',
  5: 'pH Adjuster',
  8: 'Winter Oxidizer',
  9: 'Winter Algicide',
  12: 'Maintenance Clarifier',
  16: 'Stabilizer',
  17: 'Scale Prevention',
};

export const poolProductTypeNamesFormat = formatMessage => {
  if (!isNull(formatMessage)) {
    return {
      1: formatMessage({id: 'general.sanitizer'}),
      2: formatMessage({id: 'general.oxidizer'}),
      3: formatMessage({id: 'general.maintenance.oxidizer'}),
      4: formatMessage({id: 'general.preventative.alguicide'}),
      5: formatMessage({id: 'customer.product.profile.phadjuster'}),
      8: formatMessage({id: 'general.winter.oxidizer'}),
      9: formatMessage({id: 'chemicals.winter.algicide'}),
      12: formatMessage({id: 'general.maintenance.clarifier'}),
      16: formatMessage({id: 'general.stabilizer'}),
      17: formatMessage({id: 'general.scale.prevention'}),
    };
  }
  return poolProductTypeNames;
};

export const spaProductTypeNames = {
  1: 'Sanitizer',
  2: 'Oxidizer',
  3: 'Maintenance Oxidizer',
  5: 'pH Adjuster',
  6: 'pH Buffer',
  17: 'Scale Prevention',
  18: 'TA Adjuster',
};

export const spaProductTypeNamesFormat = formatMessage => {
  if (!isNull(formatMessage)) {
    return {
      1: formatMessage({id: 'general.sanitizer'}),
      2: formatMessage({id: 'general.oxidizer'}),
      3: formatMessage({id: 'general.maintenance.oxidizer'}),
      5: formatMessage({id: 'general.ph.adjuster'}),
      6: formatMessage({id: 'general.ph.buffer'}),
      17: formatMessage({id: 'general.scale.prevention'}),
      18: formatMessage({id: 'general.ta.adjuster'}),
    };
  }
  return spaProductTypeNames;
};

export const swimSpaProductTypeNames = {
  1: 'Sanitizer',
  2: 'Oxidizer',
  3: 'Maintenance Oxidizer',
  4: 'Preventative Algicide',
  5: 'pH Adjuster',
  12: 'Maintenance Clarifier',
  17: 'Scale Prevention',
};

export const swimSpaProductTypeNamesFormat = formatMessage => {
  if (!isNull(formatMessage)) {
    return {
      1: formatMessage({id: 'general.sanitizer'}),
      2: formatMessage({id: 'general.oxidizer'}),
      3: formatMessage({id: 'general.maintenance.oxidizer'}),
      4: formatMessage({id: 'general.preventative.alguicide'}),
      5: formatMessage({id: 'customer.product.profile.phadjuster'}),
      12: formatMessage({id: 'general.maintenance.clarifier'}),
      17: formatMessage({id: 'general.scale.prevention'}),
    };
  }
  return swimSpaProductTypeNames;
};

export const waterEnhancersCustomSettings = {
  // Disable by  https://biolabinc.atlassian.net/browse/ALEX-2745 and https://biolabinc.atlassian.net/browse/ALEX-2745
  // usingOptimizerPlus: {
  //   key: 'usingOptimizerPlus',
  //   mapTo: 'optimizerProductID',
  //   condition: (dealer) => dealer.country === countries.US && dealer.state !== 'CA',
  // },
};

export const waterEnhancersPerSystem = (dealer, poolType, formatMessage) => {
  if (systemTypes.pool === poolType) {

    if (!isNull(formatMessage)) {
      return {
        usingOptimizerPlus: dealer.country !== countries.US ? formatMessage({id: 'general.optimizer.plus'}) : formatMessage({id: 'general.optimizer'}),
        usingNaturalResult: formatMessage({id: 'general.natural.result'}),
        poolOpeningComplete: formatMessage({id: 'chemicals.pool.opening.complete'}),

      };
    }

    return {
      usingOptimizerPlus: dealer.country !== countries.US  ? 'Optimizer Plus' : 'Optimizer',
      usingNaturalResult: 'Natural Result',
      poolOpeningComplete: 'Pool Opening Complete®',
    };
  }

  if (systemTypes.spa === poolType) {

    if (!isNull(formatMessage)) {
      return {
        usingVanishingAct: formatMessage({id: 'chemicals.using.vanishing.act'}),
        usingNaturalResult: formatMessage({id: 'chemicals.natural.spa.enzyme'}),
        softSoakTRIO: formatMessage({id: 'chemicals.softsoak.trio'}),
        spaComplete: formatMessage({id: 'chemicals.spa.complete'}),
      };
    }

    return {
      usingVanishingAct: 'Using Vanishing Act',
      enzymes: 'Spa Enzyme',
      softSoakTRIO: 'Softsoak TRIO',
      spaComplete: 'Spa Complete®',
    };
  }

  if (systemTypes.swimspa === poolType) {

    if (!isNull(formatMessage)) {
      return {
        usingOptimizerPlus: dealer.country !== countries.US ? formatMessage({id: 'general.optimizer.plus'}) : formatMessage({id: 'general.optimizer'}),
        enzymes: formatMessage({id: 'chemicals.natural.spa.enzyme'}),
      };
    }

    return {
      usingOptimizerPlus: dealer.country === countries.US  ? 'Optimizer Plus' : 'Optimizer',
      enzymes: 'Spa Enzyme',
    };

  }

  return [];
};

export const winterOxidizers = {
  doesNotClose: '99999',
};

export const scanTypes = {
  Manual: 0,
  Accuscan: 1,
  SmartLab: 2,
  'SmartLab 1': 3,
  'SmartLab 2': 4,
  'Xpress Flex': 5,
  "AccuFlex™": 5,
  Accuflex: 5,
};

export const propertyTypes = {
  residential: 'R',
  commercial: 'C',
};

export const locationTypes = {
  indoor: 'I',
  outdoor: 'O',
};

export const chemicalBaseTypes = {
  brominePool: 1,
  chlorinePool: 2,
  softswimPool: 3,
  chlorineSpa: 5,
  bromineSpa: 6,
  mineralSpringsPool: 7,
  softSoakSpa: 8,
  saltWaterPool: 12,
  saltWaterSpa: 13,
  synergyPool: 14,
  synergySpa: 15,
};

export const chemicalBaseTypeNames = (poolType, chemicalBaseType = 2, formatMessage = undefined) => {
  formatMessage = formatMessage || (x => <FormattedMessage id={x.id} />);

  if ([systemTypes.pool, systemTypes.spa].includes(poolType)) {
    let names = {
      1: formatMessage({id: 'general.bromine.pool'}),
      2: formatMessage({id: 'general.chlorine.pool'}),
      3: formatMessage({id: 'general.softswim.pool'}),
      5: formatMessage({id: 'general.chlorine.spa'}),
      6: formatMessage({id: 'general.bromine.spa'}),
      7: formatMessage({id: 'general.mineral.springs.pool'}),
      8: formatMessage({id: 'general.softsoak.spa'}),
      12: formatMessage({id: 'general.saltwater.pool'}),
      13: formatMessage({id: 'general.saltwater.spa'}),
      14: formatMessage({id: 'general.synergy.pool'}),
      15: formatMessage({id: 'general.synergy.spa'}),
    };

    return names[chemicalBaseType];
  }
  if ([systemTypes.swimspa].includes(poolType)) {
    let names = {
      1: formatMessage({id: 'general.bromine.swim.spa'}),
      2: formatMessage({id: 'general.chlorine.swim.spa'}),
      3: formatMessage({id: 'general.softswim.swim.spa'}),
      7: formatMessage({id: 'general.mineral.swim.spa'}),
      12: formatMessage({id: 'general.saltwater.swim.spa'}),
      14: formatMessage({id: 'general.synergy.swim.spa'}),
    };

    return names[chemicalBaseType];
  }
  return formatMessage({id: 'general.chlorine.pool'});
};

export const chemicalBaseTypeMinVolumes = {
  3: 2500,
};

export const seasonTypes = {
  opening: 'springopening',
  inSeason: 'in',
  outSeason: 'out',
  closing: 'winterclosing',
};

export const seasonTemperatureOverrides = (country = countries.US) => {
  if(country === countries.CA) {
    return {
      //Rounded to nearest integer
      [seasonTypes.outSeason]: 18,
      [seasonTypes.closing]: 7,
    };
  }else{
    return {
      [seasonTypes.outSeason]: 65,
      [seasonTypes.closing]: 45,
    };
  }

};

export const userLevels = {
  testStation: 1000,
  manager: 2000,
  dealerAdmin: 3000,
};

export const storeAdminTour = {
  id: 'manager',
  steps: [
    {
      target: '.support',
      title: 'Support',
      content: 'The answers to most common questions can be found here. Watch how-to videos, ' +
      'learn about our NEW Water Quality Score, redesigned printout, and more.',
      placement: 'right',
      padding: 0,
      xOffset: -185,
      arrowOffset: 126,
      fixedElement: true,
      yOffset: -109,
      customData: {
        borderStyle: 'border-right-color',
        borderColor: '#ebedef',
      },
    },
    {
      target: '.header-alerts',
      title: 'Notifications',
      content: 'You’ll find the latest news and notifications from BioGuard<sup>®</sup> here.',
      placement: 'bottom',
      arrowOffset: 60,
      xOffset: -39,
      fixedElement: true,
      padding: 0,
    },
    {
      target: '.tools',
      title: 'Test Connect Software',
      content: 'Stay up-to-date on the latest ALEX<sup>®</sup> Pro software in the tools section.',
      placement: 'right',
      padding: 0,
      xOffset: -185,
      fixedElement: true,
      yOffset: 10,
    },
    {
      target: '.search-customers',
      title: 'Start Water Analysis',
      content: 'To find previous analysis results or run a new water test, please start by finding or adding your customer.',
      placement: 'right',
      arrowOffset: 104,
      xOffset: -302,
      yOffset: -87,
      padding: 0,
      customData: {
        borderStyle: 'border-right-color',
        borderColor: '#ebedef',
      },
    },
  ],
  showPrevButton: true,
  scrollTopMargin: 100,
  customData: {
    showSteps: true,
  },
};

export const storeAdminTourFormat = formatMessage => {

  let sat = storeAdminTour;

  sat.i18n = {
    nextBtn: formatMessage({id: 'general.next'}),
    prevBtn: formatMessage({id: 'general.back'}),
    doneBtn: formatMessage({id: 'general.done'}),
  };

  if (!isNull(formatMessage)) {
    sat.steps[0].title = formatMessage({id: 'support.title'});
    sat.steps[1].title = formatMessage({id: 'general.notifications'});
    sat.steps[2].title = formatMessage({id: 'general.test.connect.software'});
    sat.steps[3].title = formatMessage({id: 'general.start.water.analysis'});

    sat.steps[0].content = formatMessage({id: 'general.answers.common.questions'});
    sat.steps[1].content = formatMessage({id: 'general.find.latest.news'});
    sat.steps[2].content = formatMessage({id: 'general.stay.uptodate.tools'});
    sat.steps[3].content = formatMessage({id: 'general.find.previous.analysis'});
  }
  return sat;
};

export const dealerAdminTour = {
  id: 'dealerAdmin',
  steps: [
    {
      target: '.support',
      title: 'Need Support?',
      content: 'Check out resources on our support page, including step-by-step videos about how to complete the Data Upload,' +
      ' ensure your test stations are set-up and everything else ALEX<sup>®</sup> Pro!',
      placement: 'right',
      arrowOffset: 140,
      padding: 0,
      xOffset: -185,
      fixedElement: true,
      yOffset: -128,
      customData: {
        borderStyle: 'border-right-color',
        borderColor: '#ebedef',
      },
    },
    {
      target: '.header-alerts',
      title: 'Notifications',
      content: 'You’ll find the latest news and notifications from BioGuard<sup>®</sup> here.',
      placement: 'bottom',
      arrowOffset: 60,
      xOffset: -39,
      fixedElement: true,
      padding: 0,
    },
    {
      target: '.tools',
      title: 'Test Connect Software',
      content: 'Stay up-to-date on the latest ALEX<sup>®</sup> Pro software in the tools section. This software will be needed on every test station computer.',
      placement: 'right',
      padding: 0,
      xOffset: -185,
      yOffset: 10,
    },
    {
      target: '.settings',
      title: 'Get Started',
      content: 'If you are the first Admin this is where you will get started. ' +
      'Follow the steps in your on boarding package to set your preferences for the system.',
      placement: 'right',
      xOffset: -185,
      fixedElement: true,
      padding: 0,
      multipage: true,
      yOffset: 10,
    },
  ],
  showPrevButton: true,
  scrollTopMargin: 100,
  customData: {
    showSteps: true,
  },
};

export const dealerAdminTourFormat = formatMessage => {
  let dat = dealerAdminTour;

  dat.i18n = {
    nextBtn: formatMessage({id: 'general.next'}),
    prevBtn: formatMessage({id: 'general.back'}),
    doneBtn: formatMessage({id: 'general.done'}),
  };

  if (!isNull(formatMessage)) {
    dat.steps[0].title = formatMessage({id: 'general.need.support'}) + '?';
    dat.steps[1].title = formatMessage({id: 'general.notifications'});
    dat.steps[2].title = formatMessage({id: 'general.test.connect.software'});
    dat.steps[3].title = formatMessage({id: 'general.get.started'});

    dat.steps[0].content = formatMessage({id: 'general.check.resources.support'});
    dat.steps[1].content = formatMessage({id: 'general.find.latest.news'});
    dat.steps[2].content = formatMessage({id: 'general.stay.uptodate.tools'});
    dat.steps[3].content = formatMessage({id: 'general.first.admin.started'});
  }
  return dat;
};

export const finishTypes = {
  B: 'Pebbled',
  C: 'Colored Plaster',
  F: 'Fiberglass',
  P: 'Painted',
  S: 'Stainless Steel',
  U: 'Unprinted Vinyl',
  V: 'Printed Vinyl',
  W: 'White Plaster',
};

export const finishTypesFormat = formatMessage => {

  if (!isNull(formatMessage)) {
    return {
      B: formatMessage({id: 'general.pebbled'}),
      C: formatMessage({id: 'general.colored.plaster'}),
      F: formatMessage({id: 'general.fiberglass'}),
      P: formatMessage({id: 'general.painted'}),
      S: formatMessage({id: 'general.stainless.steel'}),
      U: formatMessage({id: 'general.unprinted.vinyl'}),
      V: formatMessage({id: 'general.printed.vinyl'}),
      W: formatMessage({id: 'general.white.plaster'}),
    };
  }
  return finishTypes;
};

export const groundLevels = {
  A: 'Above Ground',
  I: 'In Ground',
  S: 'Splasher',
};

export const groundLevelsFormat = formatMessage => {
  if (!isNull(formatMessage)) {
    return {
      A: formatMessage({id: 'general.above.ground'}),
      I: formatMessage({id: 'general.in.ground'}),
      S: formatMessage({id: 'general.splasher'}),
    };
  }
  return groundLevels;
};

export const emailTypes = {
  welcome: 0,
  inactive: 1,
  pastDue: 2,
  problemFollowUp: 3,
  poolClosing: 4,
  productReminder: 5,
  seasonsOpening: 6,
  calendarReminder: 7,
  encyclopedia: 9,
  analysis: 11,
};

export const emailFollowUpTypes = {
  spaAlgae: 0,
  blackAlgae: 1,
  greenAlgae: 2,
  mustardAlgae: 3,
  chlorineDemand: 4,
  cloudyHazy: 5,
  foaming: 6,
  metalStaining: 7,
  otherStaining: 8,
  scale: 9,
  slimeMold: 10,
  swampy: 11,
  waterlineBuildup: 12,
};

export const emailSubTypes = {
  memorialDay: 0,
  fourthJuly: 1,
  labourDay: 2,
};

export const unitsOfMeasure = {
  1: 'oz (ounces)',
  2: 'ml (milliliters)',
  3: 'units',
  4: 'quarts',
  5: 'pounds',
  6: 'gallons',
  7: 'pint',
  8: 'gram',
  9: 'fluid ounce',
  10: 'teaspoon',
  11: 'tablespoon',
  //12: 'container', //deprecated, use another UOM
  13: 'cups',
};

export const salesProductCategory = {
  1: 'Saltwater Products',
  2: 'Sanitizer',
  3: 'Oxidizer',
  4: 'Biquanide',
  5: 'Algicide',
  6: 'Balancer',
  7: 'Specialty',
  8: 'Winter',
  9: 'Handheld',
  10: 'Testing',
  11: 'Spa Sanitizer',
  12: 'Spa Balancer',
  13: 'Cleaners',
  14: 'Spa Oxidizer',
  15: 'Spa Accessories',
  16: 'Water Enhancer',
  17: 'Biguanide System',
  18: 'Pool Tool',
  99: 'Other',
};

export const salesProductCategoryFormat = formatMessage => {
  if (!isNull(formatMessage)) {
    return {
      1: formatMessage({id: 'general.saltwater.products'}),
      2: formatMessage({id: 'general.sanitizer'}),
      3: formatMessage({id: 'general.oxidizer'}),
      4: formatMessage({id: 'general.biguanide'}),
      5: formatMessage({id: 'general.algicide'}),
      6: formatMessage({id: 'general.balancer'}),
      7: formatMessage({id: 'general.speciality'}),
      8: formatMessage({id: 'general.winter'}),
      9: formatMessage({id: 'general.handheld'}),
      10: formatMessage({id: 'general.testing'}),
      11: formatMessage({id: 'general.spa.sanitizer'}),
      12: formatMessage({id: 'general.spa.balancer'}),
      13: formatMessage({id: 'general.cleaners'}),
      14: formatMessage({id: 'general.spa.oxidizer'}),
      15: formatMessage({id: 'general.spa.accessories'}),
      16: formatMessage({id: 'general.water.enhancer'}),
      17: formatMessage({id: 'general.biguanide.system'}),
      18: formatMessage({id: 'general.pool.tool'}),
      99: formatMessage({id: 'general.other'}),
    };
  }
  return salesProductCategory;
};

export const customerEmailPreferenceOptionsNames = formatMessage => {
  if (!isNull(formatMessage)) {
    return {
      1: formatMessage({id: 'customer.alexpro.water.emails'}),
      2: formatMessage({id: 'customer.emailprefs.latest.bioguard'}),
      3: formatMessage({id: 'customer.emailprefs.news'}),
      4: formatMessage({id: 'reports.optout.all.emails'}),
    };
  }
  return {
    1: 'ALEX® Pro Water Test Emails',
    2: 'The Latest from BioGuard®',
    3: 'News From Your Pool & Spa Care Expert',
    4: 'All ALEX Pro Emails',
  };
};

export const countries = {
  US: 'US',
  CA: 'CA',
};

export const categoryExceptionsCA = {
  4: 'Biguanide',
  9: 'Handheld',
  17: 'Biguanide System',
  18: 'Pool Tool',
};

export const phosphateLevels = {
  NOTTESTED: 0,
  LOW: 1,
  HIGH: 2,
};

export const phosphateLevelsNames = formatMessage => {
  if (!isNull(formatMessage)) {
    return {
      0: formatMessage({id: 'analysis.phosphate.not.tested'}),
      1: formatMessage({id: 'analysis.phosphate.low'}),
      2: formatMessage({id: 'analysis.phosphate.high'}),
    };
  }
  return {
    0: 'Not Tested',
    1: 'Low',
    2: 'High',
  };
};
export const POSIntegrations = {
  none: 0,
  rb: 1,
  evosus: 3,
};

export const POSMonths = {
  rb: 24,
  evosus: 18,
};

export const temperatureFormat = {
  CELSIUS: 'C',
  FARENHEIT: 'F',
};

export const calculationUnits = {
  feet: 'ft',
  meter: 'm',
};

export const AlexPROSpecialArticlesTypes = {
  Encyclopedia_WelcomePage: 1,
  AlexPRO_WelcomePage: 2,
  PrivacyPolicy: 3,
  TermsOfService: 4,
  KnowledgeBase_WelcomePage: 5,
};


export const trashBinRecordTypes = {
  customer: 1,
  customerContact: 2,
  pool: 3,
  analysis: 4,
};


export const trashBinRecordTypesNames = {
  1: 'Customer',
  2: 'Customer Contact',
  3: 'Pool',
};

export const trashBinRecordTypesFormat = formatMessage => {
  if (!isNull(formatMessage)) {
    return {
      1: formatMessage({id: 'trashbin.filter.record.customer'}),
      2: formatMessage({id: 'trashbin.filter.record.customercontact'}),
      3: formatMessage({id: 'trashbin.filter.record.pool'}),
    };
  }
  return trashBinRecordTypesNames;
};


export const trashBinTimeFrames = {
  30: '30 days',
  60: '60 days',
  90: '90 days',
  365: '1 year',
  730: '2 years',
};

export const trashBinTimeFramesFormat = formatMessage => {
  if (!isNull(formatMessage)) {
    return {
      30: formatMessage({id: 'trashbin.filter.timeframe.30'}),
      60: formatMessage({id: 'trashbin.filter.timeframe.60'}),
      90: formatMessage({id: 'trashbin.filter.timeframe.90'}),
      365: formatMessage({id: 'trashbin.filter.timeframe.1y'}), // 1 year = 365 days
      730: formatMessage({id: 'trashbin.filter.timeframe.2y'}), // 2 years = 730 days
    };
  }
  return trashBinTimeFrames;
};

export const shelfGemTypes = {
  video: 'Video',
  image: 'Image',
  audio: 'Audio',
  link: 'Bookmark',
  document: 'Document',
  wiki: 'Note',
  post: 'Post',
};

// Warden ID coming from biolab-rules repo
export const optimizerProducts = {
  maximizer: 193, // deprecated
  optimizerPlus: 120,
  optimizer: 204,
};

export const scheduleReports = {
  MissingCustomers: 1,
  CustomerList: 2,
  PoolSystemsList: 3,
  SpaSystemsList: 4,
  SwimSpasystemsList: 5,
  MissingInformation: 6,
  ProductRecommendations: 7,
  OutboundEmails: 8,
  OptOutEmails: 9,
  MissedProductSales: 10,
  WaterAnalysisAccounts: 11,
};

export const scheduleReportExportURL = {
  XLSX_FR: 'FinalResultsXLSX_FR',
  CSV_FR: 'FinalResultsCSV_FR',
  XLSX: 'FinalResultsXLSX',
  CSV: 'FinalResultsCSV',
};


export const scheduleReportsStatuses = {
  ARCHIVED: 'ARCHIVED',
  COMPLETED: 'COMPLETED',
  QUEUED: 'QUEUED',
  RUNNING: 'RUNNING',
};

export const isBalanceCompleteSanitizer = sanitizer => {
  return sanitizer === 'SilkGuard Complete® Sticks' ||
         sanitizer === 'Super Soluble' ||
         sanitizer === 'PowerChlor' ||
         sanitizer === 'Brominating Tablets' ||
         sanitizer === 'BioGuard Basic 3" Tab' ||
         sanitizer === 'SilkGuard Complete® 1" Tablets' ||
         sanitizer === 'SilkGuard Complete® 3" Tablets' ||
         sanitizer === 'ProGuard 1" Tablets' ||
         sanitizer === 'ProGuard 3" Tablets' ||
         sanitizer === 'ProGuard Sticks' ||
         sanitizer === 'ProGuard Granular Dichloro' ||
         sanitizer === 'ProGuard Brominating Tablets'
}

export const phoneDescriptionLookup = {
  home: "general.phone.home",
  mobile: "general.phone.cell",
  work: "general.phone.work",
};
import moment from 'moment';

import cloneDeep from 'lodash/cloneDeep';

class Dealer {
  constructor({
                id, account, name, soldTo, shipTo, dsmName, dsmNumber, rmName, rmNumber, ownerName, ownerPhone,
                ownerEmail, dsmPhone, dsmEmail, expiry, modified, LastPOSActivity, CurrentPOSIntegration, deleted, website, fax,
                address1, address2, city, state, zip, country, buyingGroupExtension, CurrentPOSIntegrationTime,
                balanceCHTo, inSeasonTABalanceTarget, nonFoamingAlgicide, smartShockMinSize,
                doNotTreatTraceMetals, pHCalcLowOptimizer, disableDrainPopBromide, showAccu30Shutdown,
                ekoKleanPrompts, promptFeedMethod, oxysheenMaintClarifierDemand,
                defaultProductHazyWater, saltScapes, displaySpaProductsOunces, nextSpaAnalysis, nextPoolAnalysis,
                clarityFollowUp, algaeFollowUp, cdFollowUp,
                seasonOpen, seasonClose, seasonOpenMargin, seasonCloseMargin, newPlasterText,
                spaSentry, defaultHazyProduct, overrideOxidizer, surfaceProblemsFollowUp,
                requireCustomerEmail, established, platinumStatus, customerInactiveTime, beta, defaultPreview, poolScores, logo, logoLink,
                truBlueActive, truBlueSanitizer, truBlueMaintOxidizer, truBluePrevAlgicide, truBluePoolComplete,
                truBlueOptimizerPlus, spaDrainFrequency, managerPurchaseHistory,
                managerDetailedReporting, managerDashboard, testerPurchaseHistory, testerDetailedReporting, testerDashboard,
                managerDeleteCustomers, managerDeleteSystems, testerDeleteCustomers, testerDeleteSystems,
                purchaseMedian, customerLoyaltyThreshold, poolClosingComplete, truBlueSignedUp, scanner, nameMaintenanceProgram,
                defaultLocale, testConnectVersion, usingSmartLab, usingAccuScan, preferredTemperature, keyParametersRanges, isDefunct, isDemo,
                defaultOpening, defaultCoverMaintenance, defaultEnzyme, defaultPhosphate, defaultStainRemover, defaultMetals,
                defaultSwampyWater, defaultHazyWater, cyaRemover, saltwaterMagicMonthly, deafultSpaEnzyme, defaultSpaLineCleaner, balanceComplete, poolScalePrevention,
                spaScalePrevention, spapHAdjuster, spaTAAdjuster

              }) {

    this.id = id;
    this.account = account;
    this.name = name;

    this.soldTo = soldTo;
    this.shipTo = shipTo;

    this.buyingGroupExtension = buyingGroupExtension;

    this.dsmName = dsmName;
    this.dsmNumber = dsmNumber;
    this.dsmEmail = dsmEmail;
    this.dsmPhone = dsmPhone;

    this.rmName = rmName;
    this.rmNumber = rmNumber;

    this.ownerName = ownerName;
    this.ownerPhone = ownerPhone;
    this.ownerEmail = ownerEmail;

    this.expiry = expiry;
    this.modified = modified;
    this.LastPOSActivity = LastPOSActivity;
    this.CurrentPOSIntegration = CurrentPOSIntegration;
    this.CurrentPOSIntegrationTime = CurrentPOSIntegrationTime;
    this.deleted = deleted;

    this.website = website;
    this.fax = fax;

    this.address1 = address1;
    this.address2 = address2;
    this.city = city;
    this.state = state;
    this.zip = zip;
    this.country = country;

    this.balanceCHTo = balanceCHTo;
    this.nonFoamingAlgicide = nonFoamingAlgicide;
    this.smartShockMinSize = smartShockMinSize;
    this.inSeasonTABalanceTarget = inSeasonTABalanceTarget;
    this.pHCalcLowOptimizer = pHCalcLowOptimizer;
    this.doNotTreatTraceMetals = doNotTreatTraceMetals;
    this.disableDrainPopBromide = disableDrainPopBromide;
    this.showAccu30Shutdown = showAccu30Shutdown;
    this.ekoKleanPrompts = ekoKleanPrompts;
    this.promptFeedMethod = promptFeedMethod;
    this.oxysheenMaintClarifierDemand = oxysheenMaintClarifierDemand;
    this.defaultProductHazyWater = defaultProductHazyWater;
    this.saltScapes = saltScapes;
    this.displaySpaProductsOunces = displaySpaProductsOunces;
    this.nextPoolAnalysis = nextPoolAnalysis;
    this.nextSpaAnalysis = nextSpaAnalysis;
    this.clarityFollowUp = clarityFollowUp;
    this.algaeFollowUp = algaeFollowUp;
    this.surfaceProblemsFollowUp = surfaceProblemsFollowUp;
    this.cdFollowUp = cdFollowUp;
    this.seasonClose = seasonClose ? moment(seasonClose) : moment('2014 10 01', 'YYYY MM DD');
    this.seasonOpen = seasonOpen ? moment(seasonOpen) : moment('2014 04 01', 'YYYY MM DD');
    this.seasonOpenMargin = seasonOpenMargin;
    this.seasonCloseMargin = seasonCloseMargin;

    this.newPlasterText = newPlasterText;
    this.spaSentry = spaSentry;

    this.defaultHazyProduct = defaultHazyProduct;
    this.overrideOxidizer = overrideOxidizer;

    this.requireCustomerEmail = requireCustomerEmail;
    this.customerInactiveTime = customerInactiveTime || -1;

    this.defaultPreview = defaultPreview;

    this.established = established;
    this.platinumStatus = platinumStatus;
    this.beta = beta;

    this.poolScores = poolScores;

    this.logo = logo;
    this.logoLink = logoLink;

    this.truBlueActive = truBlueActive && typeof truBlueActive === 'string' ? moment(truBlueActive) : truBlueActive;
    this.truBlueSanitizer = truBlueSanitizer;
    this.truBlueMaintOxidizer = truBlueMaintOxidizer;
    this.truBluePrevAlgicide = truBluePrevAlgicide;
    this.truBlueOptimizerPlus = truBlueOptimizerPlus;
    this.truBluePoolComplete = truBluePoolComplete;

    this.spaDrainFrequency = spaDrainFrequency;

    this.managerPurchaseHistory = managerPurchaseHistory;
    this.managerDetailedReporting = managerDetailedReporting;
    this.managerDashboard = managerDashboard;
    this.testerPurchaseHistory = testerPurchaseHistory;
    this.testerDetailedReporting = testerDetailedReporting;
    this.testerDashboard = testerDashboard;

    this.managerDeleteCustomers = managerDeleteCustomers;
    this.managerDeleteSystems = managerDeleteSystems;
    this.testerDeleteCustomers = testerDeleteCustomers;
    this.testerDeleteSystems = testerDeleteSystems;

    this.purchaseMedian = purchaseMedian;
    this.customerLoyaltyThreshold = customerLoyaltyThreshold;

    this.poolClosingComplete = poolClosingComplete;

    this.truBlueSignedUp = truBlueSignedUp;
    this.scanner = scanner;

    this.nameMaintenanceProgram = nameMaintenanceProgram;
    this.defaultLocale = defaultLocale;

    this.testConnectVersion = testConnectVersion;
    this.usingSmartLab = usingSmartLab;
    this.usingAccuScan = usingAccuScan;
    this.preferredTemperature = preferredTemperature;
    this.keyParametersRanges = keyParametersRanges;

    this.isDefunct = isDefunct;
    this.isDemo = isDemo;

    this.defaultOpening = defaultOpening;
    this.defaultCoverMaintenance = defaultCoverMaintenance;
    this.defaultEnzyme = defaultEnzyme;
    this.defaultPhosphate = defaultPhosphate;
    this.defaultStainRemover = defaultStainRemover;
    this.defaultMetals = defaultMetals;
    this.defaultSwampyWater = defaultSwampyWater;
    this.defaultHazyWater = defaultHazyWater;
    this.cyaRemover = cyaRemover;
    this.saltwaterMagicMonthly = saltwaterMagicMonthly;
    this.deafultSpaEnzyme = deafultSpaEnzyme;
    this.defaultSpaLineCleaner = defaultSpaLineCleaner;
    this.balanceComplete = balanceComplete;
    this.poolScalePrevention = poolScalePrevention;
    this.spaScalePrevention = spaScalePrevention;
    this.spapHAdjuster = spapHAdjuster;
    this.spaTAAdjuster = spaTAAdjuster;
  }

  clone() {
    return cloneDeep(this);
  }

  get isTruBlueEligible() {
    return this.platinumStatus === 'PL' && this.truBlueActive;
  }

  toApiFormat() {
    return {
      ID: this.id,
      Account: this.account,
      Name: this.name,

      SoldTo: this.soldTo,
      ShipTo: this.shipTo,

      BuyingGroupExtension: this.buyingGroupExtension,

      DSMName: this.dsmName,
      DSMNumber: this.dsmNumber,
      DSMEmail: this.dsmEmail,
      DSMPhone: this.dsmPhone,

      RMName: this.rmName,
      RMNumber: this.rmNumber,

      OwnerName: this.ownerName,
      OwnerPhone: this.ownerPhone,
      OwnerEmail: this.ownerEmail,

      Expiry: moment(this.expiry),
      Modified: moment(this.modified),
      LastPOSActivity: moment(this.LastPOSActivity),
      CurrentPOSIntegration: this.CurrentPOSIntegration,
      CurrentPOSIntegrationTime: moment(this.CurrentPOSIntegrationTime),
      Deleted: this.deleted,

      Website: this.website,
      Fax: this.fax,

      Address1: this.address1,
      Address2: this.address2,
      City: this.city,
      State: this.state,
      Zip: this.zip,
      Country: this.country,

      BalanceCHTo: this.balanceCHTo,
      InSeasonTABalanceTarget: this.inSeasonTABalanceTarget,
      SmartShockMinSize: this.smartShockMinSize,
      NonFoamingAlgicide: this.nonFoamingAlgicide,
      DoNotTreatTraceMetals: this.doNotTreatTraceMetals,
      PHCalcLowOptimizer: this.pHCalcLowOptimizer,
      DisableDrainPopBromide: this.disableDrainPopBromide,
      ShowAccu30Shutdown: this.showAccu30Shutdown,
      EkoKleanPrompts: this.ekoKleanPrompts,
      PromptFeedMethod: this.promptFeedMethod,
      OxysheenMaintClarifierDemand: this.oxysheenMaintClarifierDemand,
      DefaultProductHazyWater: this.defaultProductHazyWater,
      SaltScapes: this.saltScapes,
      DisplaySpaProductsOunces: this.displaySpaProductsOunces,
      NextPoolAnalysis: this.nextPoolAnalysis,
      NextSpaAnalysis: this.nextSpaAnalysis,
      CDFollowUp: this.cdFollowUp,
      AlgaeFollowUp: this.algaeFollowUp,
      SurfaceProblemsFollowUp: this.surfaceProblemsFollowUp,
      ClarityFollowUp: this.clarityFollowUp,
      SeasonOpen: moment(this.seasonOpen),
      SeasonClose: moment(this.seasonClose),
      SeasonOpenMargin: parseInt(this.seasonOpenMargin) * 7,
      SeasonCloseMargin: parseInt(this.seasonCloseMargin) * 7,

      NewPlasterText: this.newPlasterText,
      SpaSentry: this.spaSentry,

      DefaultHazyProduct: this.defaultHazyProduct,
      OverrideOxidizer: this.overrideOxidizer,

      RequireCustomerEmail: this.requireCustomerEmail,
      CustomerInactiveTime: this.customerInactiveTime,

      DefaultPreview: this.defaultPreview,

      Established: this.established,
      PlatinumStatus: this.platinumStatus,
      Beta: this.beta,

      PoolScores: this.poolScores,

      Logo: this.logo,
      LogoLink: this.logoLink,

      TruBlueActive: this.truBlueActive,
      TruBlueSanitizer: this.truBlueSanitizer,
      TruBlueMaintOxidizer: this.truBlueMaintOxidizer,
      TruBluePrevAlgicide: this.truBluePrevAlgicide,
      TruBlueOptimizerPlus: this.truBlueOptimizerPlus,
      TruBluePoolComplete: this.truBluePoolComplete,

      SpaDrainFrequency: this.spaDrainFrequency ? typeof this.spaDrainFrequency === 'string' ? parseInt(this.spaDrainFrequency) : this.spaDrainFrequency : 3,

      ManagerPurchaseHistory: this.managerPurchaseHistory,
      ManagerDetailedReporting: this.managerDetailedReporting,
      ManagerDashboard: this.managerDashboard,
      TesterPurchaseHistory: this.testerPurchaseHistory,
      TesterDetailedReporting: this.testerDetailedReporting,
      TesterDashboard: this.testerDashboard,

      ManagerDeleteCustomers: this.managerDeleteCustomers,
      ManagerDeleteSystems: this.managerDeleteSystems,
      TesterDeleteCustomers: this.testerDeleteCustomers,
      TesterDeleteSystems: this.testerDeleteSystems,

      PurchaseMedian: this.purchaseMedian,
      CustomerLoyaltyThreshold: this.customerLoyaltyThreshold,
      PoolClosingComplete: this.poolClosingComplete,

      TrublueSignedup: this.truBlueSignedUp,
      Scanner: this.scanner,

      NameMaintenanceProgram: this.nameMaintenanceProgram,
      DefaultLocale: this.defaultLocale,

      TestConnectVersion: this.testConnectVersion,
      UsingSmartLab: this.usingSmartLab,
      UsingAccuScan: this.usingAccuScan,
      PreferredTemperature: this.preferredTemperature,
      KeyParametersRanges: this.keyParametersRanges,

      IsDefunct: this.isDefunct,
      IsDemo: this.isDemo,
      DefaultOpening: this.defaultOpening,
      DefaultCoverMaintenance: this.defaultCoverMaintenance,
      DefaultEnzyme: this.defaultEnzyme,
      DefaultPhosphate: this.defaultPhosphate,
      DefaultStainRemover: this.defaultStainRemover,
      DefaultMetals: this.defaultMetals,
      DefaultSwampyWater: this.defaultSwampyWater,
      DefaultHazyWater: this.defaultHazyWater,
      CYARemover: this.cyaRemover,
      SaltwaterMagicMonthly: this.saltwaterMagicMonthly,
      DeafultSpaEnzyme: this.deafultSpaEnzyme,
      DefaultSpaLineCleaner: this.defaultSpaLineCleaner,
      BalanceComplete: this.balanceComplete,
      PoolScalePrevention: this.poolScalePrevention,
      SpaScalePrevention: this.spaScalePrevention,
      SpapHAdjuster: this.spapHAdjuster,
      SpaTAAdjuster: this.spaTAAdjuster,
    };
  }

  static fromApiFormat(api) {
    let props = {
      id: api.ID,
      account: api.Account,
      name: api.Name,

      soldTo: api.SoldTo,
      shipTo: api.ShipTo,

      buyingGroupExtension: api.BuyingGroupExtension,

      dsmName: api.DSMName,
      dsmNumber: api.DSMNumber,
      dsmEmail: api.DSMEmail,
      dsmPhone: api.DSMPhone,

      rmName: api.RMName,
      rmNumber: api.RMNumber,

      ownerName: api.OwnerName,
      ownerPhone: api.OwnerPhone,
      ownerEmail: api.OwnerEmail,

      expiry: moment(api.Expiry),
      modified: moment(api.Modified),
      LastPOSActivity: moment(api.LastPOSActivity),
      CurrentPOSIntegration: api.CurrentPOSIntegration,
      CurrentPOSIntegrationTime: moment(api.CurrentPOSIntegrationTime),
      deleted: api.Deleted,

      website: api.Website,
      fax: api.Fax,

      address1: api.Address1,
      address2: api.Address2,
      city: api.City,
      state: api.State,
      zip: api.Zip,
      country: api.Country,

      balanceCHTo: api.BalanceCHTo,
      inSeasonTABalanceTarget: api.InSeasonTABalanceTarget,
      smartShockMinSize: api.SmartShockMinSize,
      nonFoamingAlgicide: api.NonFoamingAlgicide,
      doNotTreatTraceMetals: api.DoNotTreatTraceMetals,
      pHCalcLowOptimizer: api.PHCalcLowOptimizer,
      disableDrainPopBromide: api.DisableDrainPopBromide,
      showAccu30Shutdown: api.ShowAccu30Shutdown,
      ekoKleanPrompts: api.EkoKleanPrompts,
      promptFeedMethod: api.PromptFeedMethod,
      oxysheenMaintClarifierDemand: api.OxysheenMaintClarifierDemand,
      defaultProductHazyWater: api.DefaultProductHazyWater,
      saltScapes: api.SaltScapes,
      displaySpaProductsOunces: api.DisplaySpaProductsOunces,
      nextSpaAnalysis: api.NextSpaAnalysis,
      nextPoolAnalysis: api.NextPoolAnalysis,
      clarityFollowUp: api.ClarityFollowUp,
      surfaceProblemsFollowUp: api.SurfaceProblemsFollowUp,
      cdFollowUp: api.CDFollowUp,
      algaeFollowUp: api.AlgaeFollowUp,
      seasonOpen: api.SeasonOpen,
      seasonClose: api.SeasonClose,
      seasonOpenMargin: Math.ceil(api.SeasonOpenMargin / 7),
      seasonCloseMargin: Math.ceil(api.SeasonCloseMargin / 7),

      newPlasterText: api.NewPlasterText,
      spaSentry: api.SpaSentry,

      defaultHazyProduct: api.DefaultHazyProduct,
      overrideOxidizer: api.OverrideOxidizer,

      requireCustomerEmail: api.RequireCustomerEmail,
      customerInactiveTime: api.CustomerInactiveTime,

      defaultPreview: api.DefaultPreview,

      established: api.Established,
      platinumStatus: api.PlatinumStatus,
      beta: api.Beta,

      poolScores: api.PoolScores,

      logo: api.Logo,
      logoLink: api.LogoLink,

      truBlueActive: api.TruBlueActive,
      truBlueSanitizer: api.TruBlueSanitizer,
      truBlueMaintOxidizer: api.TruBlueMaintOxidizer,
      truBluePrevAlgicide: api.TruBluePrevAlgicide,
      truBlueOptimizerPlus: api.TruBlueOptimizerPlus,
      truBluePoolComplete: api.TruBluePoolComplete,

      spaDrainFrequency: api.SpaDrainFrequency,

      managerPurchaseHistory: api.ManagerPurchaseHistory,
      managerDetailedReporting: api.ManagerDetailedReporting,
      managerDashboard: api.ManagerDashboard,
      testerPurchaseHistory: api.TesterPurchaseHistory,
      testerDetailedReporting: api.TesterDetailedReporting,
      testerDashboard: api.TesterDashboard,
      managerDeleteCustomers: api.ManagerDeleteCustomers,
      managerDeleteSystems: api.ManagerDeleteSystems,
      testerDeleteCustomers: api.TesterDeleteCustomers,
      testerDeleteSystems: api.TesterDeleteSystems,

      purchaseMedian: api.PurchaseMedian,
      customerLoyaltyThreshold: api.CustomerLoyaltyThreshold,
      poolClosingComplete: api.PoolClosingComplete,

      truBlueSignedUp: api.TrublueSignedup,
      scanner: api.Scanner,

      nameMaintenanceProgram: api.NameMaintenanceProgram,
      defaultLocale: api.DefaultLocale,

      testConnectVersion: api.TestConnectVersion,
      usingAccuScan: api.UsingAccuScan ? moment(api.UsingAccuScan) : api.UsingAccuScan,
      usingSmartLab: api.UsingSmartLab ? moment(api.UsingSmartLab) : api.UsingSmartLab,
      preferredTemperature: api.PreferredTemperature,
      keyParametersRanges: api.KeyParametersRanges,

      isDefunct: api.IsDefunct,
      isDemo: api.IsDemo,

      defaultOpening: api.DefaultOpening,
      defaultCoverMaintenance: api.DefaultCoverMaintenance,
      defaultEnzyme: api.DefaultEnzyme,
      defaultPhosphate: api.DefaultPhosphate,
      defaultStainRemover: api.DefaultStainRemover,
      defaultMetals: api.DefaultMetals,
      defaultSwampyWater: api.DefaultSwampyWater,
      defaultHazyWater: api.DefaultHazyWater,
      cyaRemover: api.CYARemover,
      saltwaterMagicMonthly: api.SaltwaterMagicMonthly,
      deafultSpaEnzyme: api.DeafultSpaEnzyme,
      defaultSpaLineCleaner: api.DefaultSpaLineCleaner,
      balanceComplete: api.BalanceComplete,
      poolScalePrevention: api.PoolScalePrevention,
      spaScalePrevention: api.SpaScalePrevention,
      spapHAdjuster: api.SpapHAdjuster,
      spaTAAdjuster: api.SpaTAAdjuster,
    };

    return new Dealer(props);
  }
}

export default Dealer;

import events from 'lib/events';
import {
  chemicalBaseTypes, locationTypes, productTypes, propertyTypes,
  systemTypes, winterOxidizers, countries,
} from 'helpers/constants';

const productCategories = [
  'sanitizer',
  'maintOxidizer',
  'prevAlgicide',
  'maintClarifier',
  'winterOxidizer',
  'winterAlgicide',
  'oxidizer',
  'pHAdjuster',
  'pHBuffer',
  'stabilizer',
  'scalePrevention',
  'taAdjuster'
];

function getProductDict({pool, products, dealer, prevPool = pool, customFilter, noFilter = false, formatMessage, onlyCustomFilter = null}) {
  let errors = [];
  let productDict = {};

  for (let category of productCategories) {
    errors.push(...filterProducts({
      pool,
      prevPool,
      dealer,
      products,
      formatMessage,
      customFilter,
      data: productDict,
      key: category,
      typeID: productTypes[category],
      noFilter,
      onlyCustomFilter,
    }));
  }

  if (errors.length > 0) {
    events.publish('warn.user', {
      message: errors.map(e => e + '\n'),
      uniquenessKey: 'product-dict-warnings',
      key: 'product-dict-warnings',
    });
  } else {
    events.publish('cancel.error', {key: 'product-dict-warnings'});
  }

  return {productDict, pool, chemBaseType: productDict.sanitizer && productDict.sanitizer.chemicalBaseType || null};
}

function filterProducts({pool, prevPool, dealer, customFilter, products, data, key, typeID, formatMessage, noFilter = false, onlyCustomFilter = null}) {
  let names = [];
  let keys = [];
  let all = [];
  let errors = [];

  let chemBaseType = data.sanitizer && data.sanitizer.chemicalBaseType || null;
  let saltscapes = dealer && dealer.saltScapes || null;

  // User manually changed water feature
  let initialWaterFeatureChange = false;
  // If turning water feature on might need to override with non-foaming algicide

  if (pool.jettedReturns && !prevPool.jettedReturns ||
    pool.infloorCleaner && !prevPool.infloorCleaner ||
    pool.attachedSpa && !prevPool.attachedSpa ||
    pool.waterFountain && !prevPool.waterFountain ||
    pool.waterfall && !prevPool.waterfall) {
    initialWaterFeatureChange = pool.typeID === prevPool.typeID; // Only care for same pool type since sanitizers need certain water features
  }

  let poolTypeChange = pool.typeID !== prevPool.typeID;
  let poolLocationChange = pool.location !== prevPool.location;
  let sanitizerChange = pool.sanitizer !== prevPool.sanitizer;
  let volumeChange = pool.volume !== prevPool.volume;
  let softSoakTRIOChange = pool.softSoakTRIO !== prevPool.softSoakTRIO;

  let minCondition = product => typeID === productTypes.sanitizer && pool.typeID === systemTypes.swimspa || !product ||
    !product.minVolume || !pool || !pool.volume || product.minVolume <= pool.volume;
  let maxCondition = product => typeID === productTypes.sanitizer && pool.typeID === systemTypes.swimspa || !product ||
    !product.maxVolume || !pool || !pool.volume || product.maxVolume >= pool.volume;

  let current;
  let chemicalBaseType;

  if (products.length > 0) {
    // Override for getting all except filtering on pool type and product type regardless of if it's a valid product combinations
    if (noFilter) {
      all = products
        .filter(p => p.poolTypeID === pool.typeID && p.productTypeID === typeID && p.country === (dealer.country || 'US'))
        .sort((a, b) => a.order < b.order ? -1 : 1);
    } else if (!noFilter && onlyCustomFilter !== null) {
      all = products
        .filter(p => p.poolTypeID === pool.typeID && 
          p.productTypeID === typeID && 
          p.country === (dealer.country || 'US') &&
          (onlyCustomFilter(p) === true))
        .sort((a, b) => a.order < b.order ? -1 : 1);
    } else {
      // NOTE: Since this is such a HUGE condition, try to keep each conditional statement on
      // its own line so it is easy to parse out just what is going on in the entire thing
      all = products.filter(p => p.productTypeID === typeID && // Filter products by type
        p.poolTypeID === pool.typeID && // Filter by pool type
        p.country === (dealer.country || 'US') &&
        (!chemBaseType ||
          p.chemicalBaseTypeID === chemBaseType &&
          minCondition(p) &&
          maxCondition(p)
        ) &&
        (typeID !== productTypes.sanitizer ||
          typeID === productTypes.sanitizer &&
          !pool.softSoakTRIO &&
          (p.name.toLowerCase() === 'softsoak' ||
            !p.name.toLowerCase().includes('soak')
          ) ||
          pool.softSoakTRIO &&
          p.name.toLowerCase() !== 'softsoak'

        ) &&
        (typeID !== productTypes.maintOxidizer ||
          typeID === productTypes.maintOxidizer &&
          !pool.softSoakTRIO &&
          !p.name.toLowerCase().includes('clarifier') ||
          pool.softSoakTRIO &&
          (p.name.toLowerCase().includes('clarifier') || p.name.toLowerCase().includes('trio'))

        ) &&
        (typeID !== productTypes.oxidizer ||
          typeID === productTypes.oxidizer &&
          (!pool.softSoakTRIO &&
            !p.name.toLowerCase().includes('trio') ||
            pool.softSoakTRIO
          )
        ) &&
        //Filter out phBuffer when pool SoftSoakTRIO is enabled
        (typeID !== productTypes.pHBuffer || typeID === productTypes.pHBuffer && !pool.softSoakTRIO) &&
        //Filter out None option for outdoor pools
        (typeID !== productTypes.stabilizer ||
          p.code !== '99999' || pool.location !== locationTypes.outdoor) &&
        // Filter stabilizers to not include saltscapes sunshield (16016) if mineral springs/saltwater pool not using saltscapes
        (typeID !== productTypes.stabilizer ||
          chemBaseType !== chemicalBaseTypes.mineralSpringsPool &&
          chemicalBaseType !== chemicalBaseTypes.saltWaterPool ||
          saltscapes ||
          p.code !== '16016') &&
        (!customFilter || customFilter(p) === true) &&
        (saltscapes || !p.name.toLowerCase().includes('saltscapes'))
      ).sort((a, b) => a.order < b.order ? -1 : 1);

      if (pool.property === propertyTypes.residential) {
        all = all.map(p => {
          if (p.proGuardInd) {
            p = p.clone();
            p.order += 100;
          }
          return p;
        })
          .sort((a, b) => a.order < b.order ? -1 : 1);
      }

      // If water features and non-foaming algicide selected override sort order and set non-foaming algicide as first product
      if (dealer && dealer.nonFoamingAlgicide && initialWaterFeatureChange && typeID === productTypes.prevAlgicide) {
        pool[key] = null; // Reset prev algicide if select water feature so that non-foaming algicide occurs

        let overrideSortOrder = all.filter(x => x.wardenID === dealer.nonFoamingAlgicide);

        all = overrideSortOrder.concat(all.filter(x => x.wardenID !== dealer.nonFoamingAlgicide));
      }

      if (typeID === productTypes.maintClarifier) {
        // Make Pool Complete the default maintenance clarifier if Dealer is from Canada.
        // If Dealer is from US default to Pool Juice Weekly
        const defaultMaintClarifier = dealer.country === countries.US ? '17400' : '23763';

        all = all.filter(x => x.code === defaultMaintClarifier).concat(all.filter(x => x.code !== defaultMaintClarifier));
      }
    }

    current = null;

    // If a product was selected and it is still in the product dict then keep it selected
    if (all.length > 0 && pool[key]) {
      let prods = all.filter(a => a.id === pool[key]);

      if (prods.length > 0) {
        current = prods[0];
      }
    }

    for (let prod of all) {
      let name = prod.name;

      if (formatMessage && typeof formatMessage === 'function') {
        if (name === 'None') {
          name = formatMessage({id: 'general.none'});
        } else if (name === 'Does Not Close') {
          name = formatMessage({id: 'general.does.not.close'});
        } else if (name === 'Salt') {
          name = formatMessage({id: 'general.salt'});
        } else if (name === 'Saltwater') {
          name = formatMessage({id: 'general.saltwater'});
        }
      }

      if (names.indexOf(name) === -1) {
        names.push(name);
        keys.push(prod.id);
      }
    }

    // If nothing is selected, choose the first in the list
    if (!current || !current.id || keys.indexOf(current.id) === -1) {
      current = all[0];
      // Override default sanitizer
      if (key === 'sanitizer' && dealer.country === countries.US) {
        if (pool.typeID === systemTypes.swimspa || pool.typeID === systemTypes.pool) {
          //SilkGuard Complete{registered} 3" Tablets [ALEX-2971].
          current = all.find(x => x.code === '52360');
        }
        if (pool.typeID === systemTypes.spa && softSoakTRIOChange && !pool.softSoakTRIO && pool.sanitizer &&
          (pool.chemicalBaseTypeID === 5 || pool.chemicalBaseTypeID === 6) && dealer.country === countries.US) {
          // [ALEX-277] Clear softSoakTrio if pool volume does not meet volume conditions and reset sanitizer defaults according chemical chemicalBaseType
          // If Soft Soak Brominating Concentrate (10410) reset to Brominating Concentrate (10100)
          // If Soft Soak Chlorinating Concentrate (10400) reset to Chlorinating Concentrate (10200)
          current = pool.chemicalBaseTypeID === 5 ? all.find(x => x.code === '10200') : all.find(x => x.code === '10100');
        }
      }

      // Default spa sanitizer is brominating tablets
      if (key === 'sanitizer' && dealer.country === countries.CA && pool.typeID === systemTypes.spa && pool.typeID !== prevPool.typeID) {
        current = all.find(x => x.wardenID === 42);
      }

      if (key === 'prevAlgicide' && (pool.typeID === systemTypes.pool || pool.typeID === systemTypes.swimspa) && dealer.country === countries.US) {
        if([chemicalBaseTypes.chlorinePool, chemicalBaseTypes.brominePool].includes(chemBaseType)) {
          //Algae Complete [ALEX-364]
          current = all.find(x => x.code === '23075');
        } else if(chemicalBaseTypes.mineralSpringsPool === chemBaseType) {
          //N/A No Algicide Needed
          current = all.find(x => x.code === '99999');
        } else if (chemicalBaseTypes.saltWaterPool === chemBaseType) {
          //SaltScapes Algae Remover
          current = all.find(x => x.code === '3900');
        }
      }

      // defaulting to dealer selected default or None (None's Warden ID is 61, Code is 99999)
      if (key === 'scalePrevention'){
        // Pool and Swim Spa Scale Prevention default value
        if (pool.typeID === systemTypes.swimspa || pool.typeID === systemTypes.pool){
          if (dealer.poolScalePrevention === 8) { // Scale inhibitor
            current = all.find(x => x.wardenID === dealer.poolScalePrevention);
          }
          else {
            current = all.find(x => x.code === '99999'); // None
          }
        }
        else {
          // Spa Scale Prevention default value
          if (dealer.spaScalePrevention === 133 || dealer.spaScalePrevention === 227) { // Stain & Scale Control and RDT Stain & Scale
            current = all.find(x => x.wardenID === dealer.spaScalePrevention);
          } 
          else {
            current = all.find(x => x.code === '99999'); // None
          }
        } 
      }

      if (key === 'pHAdjuster' && pool.typeID === systemTypes.spa && (((dealer.spapHAdjuster === 10 && dealer.country === 'US') || (dealer.spapHAdjuster === 169 && dealer.country === 'CA')) || dealer.spapHAdjuster === 226)) { // pH Decreaser and RDT pH Decreaser
        current = all.find(x => x.wardenID === dealer.spapHAdjuster);
      }

      if (key === 'taAdjuster' && pool.typeID === systemTypes.spa && (((dealer.spaTAAdjuster === 9 && dealer.country === 'US') || (dealer.spaTAAdjuster === 170 && dealer.country === 'CA')) || dealer.spaTAAdjuster === 230)) { // Total Alkalinity Increaser and RDT Alkalinity Increaser
        current = all.find(x => x.wardenID === dealer.spaTAAdjuster);
      }
    }

    // Manage a new pool location change event, so it doesn't override pool defaults when editing an existing one
    if (poolLocationChange && !pool.id) {
      // Does Not Close winterizer
      if (key === 'winterOxidizer' && dealer.country === countries.US) {
        // Indoor Pools default to 'Does Not Close' winterizer
        if (pool.typeID === systemTypes.pool && pool.location === locationTypes.indoor) {
          current = key === 'winterOxidizer' ? all.find(x => x.code === winterOxidizers.doesNotClose) : current;
        } else if (pool.typeID === systemTypes.pool && pool.location === locationTypes.outdoor) {
          // Default to standard default if outdoor
          current = all[0];
        }
      }
    }


    // Override default selected products
    if ((poolTypeChange || sanitizerChange) && pool.typeID !== systemTypes.swimspa && dealer.country === countries.US) {

      if (chemBaseType === chemicalBaseTypes.chlorinePool) {
        // Easy Shock & Swim
        current = key === 'maintOxidizer' && (!pool.volume || pool.volume <= 2500) ? all.find(x => x.code === '1800') : current;
        // Spa Shock
        current = key === 'maintOxidizer' && pool.volume > 2500 ? all.find(x => x.code === '1500') : current;
      }

      if (chemBaseType === chemicalBaseTypes.chlorinePool || chemBaseType === chemicalBaseTypes.brominePool) {
        // Easy Shock & Swim
        current = key === 'oxidizer' && (!pool.volume || pool.volume <= 2500) ? all.find(x => x.code === '1800') : current;
        // BurnOut 3
        current = key === 'oxidizer' && pool.volume > 2500 ? all.find(x => x.code === '1175') : current;
      }

      if (chemBaseType === chemicalBaseTypes.bromineSpa) {
        // Spa Shock
        current = key === 'oxidizer' ? all.find(x => x.code === '11500') : current;
        current = key === 'maintOxidizer' && !pool.softSoakTRIO ? all.find(x => x.code === '12500') : current;
      }

      if (chemBaseType === chemicalBaseTypes.chlorineSpa || chemBaseType === chemicalBaseTypes.saltWaterSpa) {
        // SpaGuard Enhanced Shock
        current = key === 'maintOxidizer' && !pool.softSoakTRIO ? all.find(x => x.code === '12400') : current;
      }

      if (pool.typeID === systemTypes.pool && pool.location === locationTypes.indoor) {
        current = key === 'winterOxidizer' ? all.find(x => x.code === winterOxidizers.doesNotClose) : current;
      }

      if (chemBaseType === chemicalBaseTypes.chlorinePool) {
        // Easy Shock & Swim
        current = key === 'maintOxidizer' && (!pool.volume || pool.volume <= 2500) ? all.find(x => x.code === '1800') : current;
        // Spa Shock
        current = key === 'maintOxidizer' && pool.volume > 2500 ? all.find(x => x.code === '1500') : current;
      }
    }

    if ((poolTypeChange || sanitizerChange) && pool.typeID !== systemTypes.spa && dealer.country === countries.US) {
      //exclusive defaults for swimspa and pools
      if([chemicalBaseTypes.chlorinePool, chemicalBaseTypes.brominePool].includes(chemBaseType)) {
        //Algae Complete [ALEX-364]
        current = key === 'prevAlgicide' ? all.find(x => x.code === '23075') : current;
      } else if(chemicalBaseTypes.mineralSpringsPool === chemBaseType) {
        //N/A No Algicide Needed
        current = key === 'prevAlgicide' ? all.find(x => x.code === '99999') : current;
      } else if (chemicalBaseTypes.saltWaterPool === chemBaseType) {
        current = key === 'prevAlgicide' ? all.find(x => x.code === '3900') : current;
      }
    }
    // End override default selected products


    if (current) {
      chemicalBaseType = current.chemicalBaseTypeID;
      pool.chemicalBaseTypeID = chemicalBaseType;

      if (chemicalBaseType === chemicalBaseTypes.mineralSpringsPool) {
        pool.usingOptimizerPlus = true; // Always true for mineral springs pools
      }

      if (typeID !== productTypes.sanitizer || chemicalBaseType !== chemicalBaseTypes.brominePool ||
        pool.typeID === systemTypes.pool ||
        pool.typeID === systemTypes.swimspa && chemicalBaseType !== chemicalBaseTypes.brominePool
      ) {
        if (!minCondition(current)) {
          errors.push(formatMessage(
            {
              id: 'products.volume.low',
            },
            {
              product: current.name,
              volume: `${current.minVolume} ${dealer.country === countries.US ? 'gals' : 'L'}`,
            }));
        }
        if (!maxCondition(current)) {
          errors.push(formatMessage(
            {
              id: 'products.volume.high',
            },
            {
              product: current.name,
              volume: `${current.maxVolume} ${dealer.country === countries.US ? 'gals' : 'L'}`,
            }));
        }
      }
    }
  }

  pool[key] = current && current.id ? current.id : null;

  data[key] = {
    names: names,
    keys: keys,
    products: all,
    current: current && current.id ? current.id : null,
    currentName: current && current.name ? current.name : '',
    chemicalBaseType: chemicalBaseType,
  };

  return errors;
}

export default getProductDict;

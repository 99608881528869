import {countries} from './constants';

const halogenPools = [1, 2, 14];
const biguanidePools = [3];
const saltPools = [12];
const mineralSpringsPools = [7];

const halogenSpas = [5, 6, 15];
const biguanideSpas = [8];
const saltSpas = [13];

export function getProducts(country, chemicalBaseTypeID, includedDiscontinued = false, includeNCProducts = true) {
  const poolType = [...order[country].keys()].find(types => types.includes(chemicalBaseTypeID));

  if (!poolType) {
    return undefined;
  }

  if (!chemicalBaseTypeID) {
    return [...order[country].values()]
      .map(list => list.map(x => products[country].find(y => y.id === x &&
        (!y.discontinued || includedDiscontinued) &&
        (!y.naturalChemistyrOnly || includeNCProducts)))).filter(y => !!y);
  }

  return order[country].get(poolType)
    .map(x => products[country].find(y => y.id === x &&
      (!y.discontinued || includedDiscontinued) &&
      (!y.naturalChemistyrOnly || includeNCProducts))).filter(y => !!y);
}


// Maps from arrays of chemical base type ids to arrays of suggested product ids
const order = {
  [countries.US]: new Map(),
  [countries.CA]: new Map(),
};

order[countries.US].set(halogenPools, [34, 35, 36, 13, 1, 7, 22, 6, 21, 4, 8, 3, 14, 11, 9, 23, 24, 25, 26, 27, 37, 38]);
order[countries.US].set(biguanidePools, [34, 35, 36, 13, 7, 22, 6, 21, 4, 3, 14, 11, 23, 24, 25, 26, 27]);
order[countries.US].set(saltPools, [34, 35, 36, 12, 13, 7, 22, 6, 21, 4, 8, 3, 14, 11, 23, 24, 25, 26, 27, 28, 38]);
order[countries.US].set(mineralSpringsPools, [34, 35, 36, 13, 12, 2, 6, 21, 4, 14, 11, 9, 23, 24, 25, 26, 27, 38]);
order[countries.US].set(halogenSpas, [6, 17, 18, 5, 16, 19, 20, 15, 29, 30, 39, 40, 41, 42, 43, 44]);
order[countries.US].set(biguanideSpas, [6, 18, 30]);
order[countries.US].set(saltSpas, [6, 17, 18, 5, 16, 19, 20, 15, 29, 39, 40, 41, 42, 43, 44]);

order[countries.CA].set(halogenPools, [6, 5, 19, 3, 7, 2, 12, 9, 10, 8, 21, 22, 23, 24, 27, 28]);
order[countries.CA].set(saltPools, [11, 6, 5, 19, 3, 7, 2, 12, 9, 10, 8, 21, 22, 23, 24, 27, 28]);
order[countries.CA].set(mineralSpringsPools, [11, 1, 5, 19, 3, 12, 10, 8, 9, 22, 23, 24, 27, 28]);
order[countries.CA].set(halogenSpas, [20, 15, 16, 4, 14, 18, 17, 13, 25, 26, 29, 30, 31, 32]);
order[countries.CA].set(saltSpas, [20, 15, 16, 4, 14, 18, 17, 13, 25, 26, 29, 30, 31, 32]);

const products = {
  [countries.US]: [
    {
      id: 1,
      wardenID: 28,
      name: 'Algae Complete®',
      initialDose: volume => ({number: volume / 10000 * 8, unit: 'oz'}),
      maintenanceDose: volume => ({number: volume / 10000 * 1.5, unit: 'oz'}),
      clarity: true,
      comfort: true,
      tag: 'algae-complete',
    },
    {
      id: 2,
      wardenID: 21,
      name: 'Mineral Springs Stain And Scale',
      maintenanceDose: volume => ({number: volume / 5000, unit: 'qt'}),
      clarity: true,
      comfort: true,
      tag: 'mineral-springs-stain-scale',
    },
    {
      id: 3,
      wardenID: 60,
      name: 'Natural Clarifier',
      maintenanceDose: volume => ({number: volume / 5000, unit: 'qt'}),
      clarity: true,
      comfort: true,
      tag: 'natural-clarifier',
    },
    {
      id: 4,
      wardenID: 118,
      name: 'Natural Result',
      maintenanceDose: volume => ({number: volume / 2000, unit: 'oz'}),
      tag: 'natural-result',
    },
    {
      id: 5,
      wardenID: 16,
      name: 'Natural Spa Enzyme',
      initialDose: volume => ({number: volume / 50, unit: 'tbsp'}),
      maintenanceDose: volume => ({number: volume / 100, unit: 'oz'}),
      clarity: true,
      tag: 'natural-spa-enzyme',
    },
    {
      id: 6,
      wardenID: 119,
      name: 'Off The Wall',
      protection: true,
      tag: 'off-the-wall',
    },
    {
      id: 7,
      wardenID: 120,
      name: 'Optimizer Plus',
      initialDose: volume => ({number: volume / 10 * 35 * 0.57, unit: 'lb'}),
      tag: 'optimizer-plus',
      discontinued: true,
    },
    {
      id: 8,
      wardenID: 62,
      name: 'Polysheen Blue',
      maintenanceDose: volume => ({number: volume / 5000, unit: 'oz'}),
      clarity: true,
      comfort: true,
      tag: 'polysheen-blue',
    },
    {
      id: 9,
      wardenID: 121,
      name: 'Pool Closing Complete®',
      text: `suggested-products.us`,
      initialDose: volume => ({number: volume / 25000, unit: 'bottle'}),
      tag: 'pool-closing-complete',
    },
    {
      id: 11,
      wardenID: 122,
      name: 'Pool Opening Complete®',
      initialDose: volume => ({number: volume / 20000, unit: 'qt'}),
      tag: 'pool-opening-complete',
    },
    {
      id: 12,
      wardenID: 116,
      name: 'Mineral Springs® Cell Cleaner',
      tag: 'mineral-springs-cell-cleaner',
    },
    {
      id: 13,
      wardenID: null,
      name: 'Skim Mor w/Myclex',
      protection: true,
      tag: 'skim-mor',
    },
    {
      id: 14,
      wardenID: 127,
      name: 'Smart Shield',
      maintenanceDose: volume => ({number: volume / 15000 * 8, unit: 'oz'}),
      tag: 'smart-shield',
    },
    {
      id: 15,
      wardenID: 128,
      name: 'Soft Soak® TRIO® Spa Kit - New',
      tag: 'soft-soak-trio',
    },
    {
      id: 16,
      wardenID: 148,
      name: 'Spa Complete®',
      initialDose: volume => ({number: volume / 100, unit: 'capful'}),
      maintenanceDose: volume => ({number: volume / 250 * 0.5, unit: 'capful'}),
      clarity: true,
      comfort: true,
      tag: 'spa-complete',
    },
    {
      id: 17,
      wardenID: 133,
      name: 'Spa Stain and Scale Control',
      text: ``,
      initialDose: volume => ({number: volume / 150, unit: 'tbsp'}),
      maintenanceDose: volume => ({number: volume / 300, unit: 'oz'}),
      clarity: true,
      protection: true,
      tag: 'spa-stain-scale-control',
    },
    {
      id: 18,
      wardenID: 25,
      name: 'Spa System Flush',
      tag: 'spa-system-flush',
    },
    {
      id: 19,
      wardenID: null,
      name: 'Spa Water Clarifier',
      initialDose: volume => ({number: volume / 300, unit: 'tbsp'}),
      maintenanceDose: volume => ({number: volume / 600, unit: 'oz'}),
      clarity: true,
      comfort: true,
      tag: 'spa-water-clarifier',
    },
    {
      id: 20,
      wardenID: null,
      name: 'Spa Water Freshener',
      tag: 'spa-water-freshener',
    },
    {
      id: 21,
      wardenID: 136,
      name: 'Stow-Away',
      tag: 'stow-away',
    },
    {
      id: 22,
      wardenID: 204,
      name: 'Optimizer',
      initialDose: volume => ({number: Math.round(15.05 * (volume / 10 / 1000)), unit: 'lb'}),
      tag: 'optimizer',
    },
    {
      id: 23,
      wardenID: 206,
      name: 'Spray-On Cleaner™',
      tag: 'spray-on-cleaner',
      maintenanceDoseText: null,
      initialDoseText: null,
      naturalChemistyrOnly: true,
    },
    {
      id: 24,
      wardenID: null,
      name: 'Clean & Perfect™',
      tag: 'clean-n-perfect',
      maintenanceDoseText: null,
      initialDoseText: null,
      naturalChemistyrOnly: true,
    },
    {
      id: 25,
      wardenID: null,
      name: 'PURGE™',
      tag: 'purge',
      maintenanceDoseText: null,
      initialDoseText: 'suggested-products.us.initial-dose.purge',
      naturalChemistyrOnly: true,
    },
    {
      id: 26,
      wardenID: 205,
      name: 'Pool First Aid™',
      tag: 'pool-first-aid',
      maintenanceDoseText: null,
      initialDoseText: 'suggested-products.us.initial-dose.pool-first-aid',
      naturalChemistyrOnly: true,
    },
    {
      id: 27,
      wardenID: 207,
      name: 'Pool Perfect® MAX',
      tag: 'pool-perfect-max',
      maintenanceDoseText: 'suggested-products.us.maintenance-dose.pool-perfect-max',
      initialDoseText: null,
      naturalChemistyrOnly: true,
    },
    {
      id: 28,
      wardenID: 213,
      name: 'Salt Water Magic Monthly Kit™',
      tag: 'salt-water-magic-monthly',
      maintenanceDoseText: null,
      initialDoseText: null,
      naturalChemistyrOnly: true,
    },
    {
      id: 29,
      wardenID: 211,
      name: 'Spa Perfect®',
      tag: 'spa-perfect',
      maintenanceDoseText: 'suggested-products.us.maintenance-dose.spa-perfect',
      initialDoseText: null,
      naturalChemistyrOnly: true,
    },
    {
      id: 30,
      wardenID: 212,
      name: 'Spa PURGE™',
      tag: 'spa-purge',
      maintenanceDoseText: null,
      initialDoseText: null,
      naturalChemistyrOnly: true,
    },
    {
      id: 34,
      wardenID: 221,
      name: 'Zero Phos™',
      tag: 'zero-phos',
      maintenanceDoseText: null,
      initialDoseText: 'suggested-products.us.initial-dose.zero-phos',
    },
    {
      id: 35,
      wardenID: 31,
      name: 'Pool Complete® Weekly',
      tag: 'pool-complete-weekly',
      maintenanceDoseText: null,
      initialDoseText: 'suggested-products.us.initial-dose.pool-complete-weekly',
    },
    {
      id: 36,
      wardenID: 223,
      name: 'Pool Complete® 911',
      tag: 'pool-complete-911',
      maintenanceDoseText: null,
      initialDoseText: 'suggested-products.us.initial-dose.pool-complete-911',
    },
    {
      id: 37,
      wardenID: 224,
      name: 'Balance Complete®',
      tag: 'balance-complete',
      initialDoseText: 'suggested-products.us.initial-dose.balance-complete',
    },
    {
      id: 38,
      wardenID: 225,
      name: 'Basic Shock',
      tag: 'basic-shock',
      initialDoseText: 'suggested-products.us.initial-dose.basic-shock',
    },
    {
      id: 39,
      wardenID: 225,
      name: 'Rapid-Dissolve Alkalinity Increaser Tabs',
      tag: 'alkalinity-increaser-tabs',
    },
    {
    id: 41,
    wardenID: 226,
    name: 'Rapid-Dissolve pH Decreaser Tabs',
    tag: 'pH-decreaser-tabs',
    },
    {
       id: 42,
       wardenID: 230,
       name: 'Rapid-Dissolve Stain & Scale Tabs',
       tag: 'stain-&-scale-tabs',
    },
    {
      id: 43,
      wardenID: 228,
      name: 'Rapid-Dissolve Shock-Oxidizing Tabs',
      tag: 'shock-oxidizing-tabs',
    },
    {
      id: 44,
      wardenID: 229,
      name: 'Rapid-Dissolve Chlorinating Tabs',
      tag: 'chlorinating-tabs',
    }
  ],
  [countries.CA]: [
    {
      id: 1,
      wardenID: 23,
      name: 'Mineral Springs Stain and Scale',
      maintenanceDose: volume => ({number: volume / 20, unit: 'L'}),
      protection: true,
      tag: 'mineral-springs-stain-scale',
    },
    {
      id: 2,
      wardenID: 60,
      name: 'Natural Clarifier',
      maintenanceDose: volume => ({number: volume / 10 * 15, unit: 'mL'}),
      clarity: true,
      tag: 'natural-clarifier',
    },
    {
      id: 3,
      wardenID: 118,
      name: 'Natural Result',
      initialDose: volume => ({number: volume / 10 * 120, unit: 'mL'}),
      maintenanceDose: volume => ({number: volume / 10 * 40, unit: 'mL'}),
      clarity: true,
      protection: true,
      tag: 'natural-result',
    },
    {
      id: 4,
      wardenID: 16,
      name: 'Natural Spa Enzyme',
      maintenanceDose: volume => ({number: volume / 1500 * 125, unit: 'mL'}),
      clarity: true,
      protection: true,
      tag: 'natural-spa-enzyme',
    },
    {
      id: 5,
      wardenID: 119,
      name: 'Off The Wall',
      protection: true,
      tag: 'off-the-wall',
    },
    {
      id: 6,
      wardenID: 120,
      name: 'Optimizer Plus',
      comfort: true,
      clarity: true,
      initialDose: volume => ({number: volume * 30 * 0.006, unit: 'kg'}),
      tag: 'optimizer-plus',
    },
    {
      id: 7,
      wardenID: 62,
      name: 'Polysheen Blue',
      maintenanceDose: volume => ({number: volume / 10 * 15, unit: 'mL'}),
      tag: 'polysheen-blue',
    },
    {
      id: 8,
      wardenID: 121,
      name: 'Pool Closing Complete®',
      initialDose: volume => ({number: Math.ceil(volume / 95000), unit: 'bottle'}),
      clarity: true,
      tag: 'pool-closing-complete',
    },
    {
      id: 9,
      wardenID: 31,
      name: 'Pool Complete® Weekly',
      text: ``,
      protection: true,
      tag: 'pool-complete-weekly',
      maintenanceDoseText: 'suggested-products.ca.maintenance-dose.pool-complete-weekly',
    },
    {
      id: 10,
      wardenID: 122,
      name: 'Pool Opening Complete®',
      initialDose: volume => ({number: Math.ceil(volume / 75000), unit: 'bottle'}),
      clarity: true,
      tag: 'pool-opening-complete',
    },
    {
      id: 11,
      wardenID: 116,
      name: 'Mineral Springs® Cell Cleaner',
      tag: 'mineral-springs-cell-cleaner',
      protection: true,
    },
    {
      id: 12,
      wardenID: 127,
      name: 'Smart Shield',
      comfort: true,
      tag: 'smart-shield',
    },
    {
      id: 13,
      wardenID: 128,
      name: 'Soft Soak® TRIO® Spa Kit - New',
      initialDose: () => ({number: 1, unit: 'kit'}),
      comfort: true,
      tag: 'soft-soak-trio',
    },
    {
      id: 14,
      wardenID: 148,
      name: 'Spa Complete®',
      initialDose: volume => ({number: Math.ceil(volume / 380), unit: 'capful'}),
      maintenanceDose: volume => ({number: Math.ceil(volume / 946 * 2) / 2, unit: 'capful'}),
      protection: true,
      tag: 'spa-complete',
    },
    {
      id: 15,
      wardenID: 133,
      name: 'Spa Stain and Scale Control',
      initialDose: volume => ({number: volume / 1000 * 25, unit: 'mL'}),
      maintenanceDose: volume => ({number: volume / 1000 * 15, unit: 'mL'}),
      protection: true,
      tag: 'spa-stain-scale-control',
    },
    {
      id: 16,
      wardenID: 184,
      name: 'Swirl Away Pipe Cleaner',
      protection: true,
      tag: 'swirl-away',
    },
    {
      id: 17,
      wardenID: 185,
      name: 'Swirl Away II Surface Cleaner',
      protection: true,
      tag: 'swirl-away-ii',
    },
    {
      id: 18,
      wardenID: 157,
      name: 'Polysheen',
      text: ``,
      initialDose: volume => ({number: volume / 1000 * 20, unit: 'mL'}),
      maintenanceDose: volume => ({number: volume / 1000 * 10, unit: 'mL'}),
      clarity: true,
      tag: 'polysheen',
    },
    {
      id: 19,
      wardenID: 136,
      name: 'Stow-Away',
      text: ``,
      protection: true,
      tag: 'stow-away',
    },
    {
      id: 20,
      wardenID: 163,
      name: 'SpaGuard® Filter Brite™',
      text: ``,
      clarity: true,
      tag: 'spa-filter-brite',
    },
    {
      id: 21,
      wardenID: 165,
      name: 'Clarifying Tabs',
      text: ``,
      clarity: true,
      tag: 'clarifying-tabs',
    },
    {
      id: 22,
      wardenID: null,
      name: 'Clean & Perfect™',
      tag: 'clean-n-perfect',
      maintenanceDoseText: null,
      initialDoseText: null,
      naturalChemistyrOnly: true,
    },
    {
      id: 23,
      wardenID: 205,
      name: 'Pool First Aid™',
      tag: 'pool-first-aid',
      maintenanceDoseText: null,
      initialDoseText: 'suggested-products.ca.initial-dose.pool-first-aid',
      naturalChemistyrOnly: true,
    },
    {
      id: 24,
      wardenID: 207,
      name: 'Pool Perfect™ MAX',
      tag: 'pool-perfect-max',
      initialDoseText: null,
      maintenanceDoseText: 'suggested-products.ca.maintenance-dose.pool-perfect-max',
      naturalChemistyrOnly: true,
    },
    {
      id: 25,
      wardenID: 211,
      name: 'Spa Perfect™',
      tag: 'spa-perfect',
      maintenanceDoseText: 'suggested-products.ca.maintenance-dose.spa-perfect',
      initialDoseText: null,
      naturalChemistyrOnly: true,
    },
    {
      id: 26,
      wardenID: 212,
      name: 'Spa PURGE™',
      tag: 'spa-purge',
      maintenanceDoseText: null,
      initialDoseText: null,
      naturalChemistyrOnly: true,
    },
    {
      id: 27,
      wardenID: 221,
      name: 'Zero Phos™',
      tag: 'zero-phos',
      maintenanceDoseText: null,
      initialDoseText: null,
    },
    {
      id: 28,
      wardenID: 223,
      name: 'Pool Complete® 911',
      tag: 'pool-complete-911',
      maintenanceDoseText: 'suggested-products.ca.maintenance-dose.pool-complete-911',
    },
    {
      id: 29,
      wardenID: 225,
      name: 'Rapid-Dissolve Alkalinity Increaser Tabs',
      tag: 'alkalinity-increaser-tabs',
    },
    {
    id: 30,
    wardenID: 226,
    name: 'Rapid-Dissolve pH Decreaser Tabs',
    tag: 'pH-decreaser-tabs',
    },
     {
       id: 31,
       wardenID: 230,
       name: 'Rapid-Dissolve Stain & Scale Tabs',
       tag: 'stain-&-scale-tabs',
     },
    {
      id: 32,
      wardenID: 228,
      name: 'Rapid-Dissolve Shock-Oxidizing Tabs',
      tag: 'shock-oxidizing-tabs',
    },
    {
      id: 33,
      wardenID: 229,
      name: 'Rapid-Dissolve Chlorinating Tabs',
      tag: 'chlorinating-tabs',
    }
  ],
};

export function getReportsCategoriesCA(categories, categoryExceptionsCA) {
  let removedCategoriesCA = [];
  //Get the id of each category

  for (let key in categoryExceptionsCA) {
    if (categoryExceptionsCA.hasOwnProperty(key)) {
      removedCategoriesCA.push(key);
    }
  }
  //Override the productCategoryOptions with just the selected for CA
  return categories.filter(category => !removedCategoriesCA.includes(category.value));
}

export function getNaturalChemistryProducts(country) {
  return products[country].filter(y => !!y.naturalChemistyrOnly);
}

export default products;
